<template>
  <section>
    <template v-if="notification_only">
      <FormConfigNotification
        v-model="groups"
        :notification_mode="alarm.notification_mode"
        :notification_channels="notification_config"
        :enabled="alarm.notification_enabled"
        :canDisabled="false"
        @enable="alarm.notification_enabled = true"
        @disable="alarm.notification_enabled = false"
        @notification_mode="alarm.notification_mode = $event"
      />
      <FormFooterToolbar
        v-bind:remove="false"
        v-bind:addAnother="false"
        v-bind:busy="busy"
        v-bind:valid="isValid"
        v-bind:clone="true"
        v-bind:value="cloneEnabled"
        v-model="cloneEnabled"
        v-on:buttonCancelClick="onButtonCancelClick"
        v-on:buttonSaveClick="onButtonSaveClick"
        v-on:buttonCloneClick="onButtonCloneClick"
        v-on:buttonRemoveClick="onButtonRemoveClick"
        rule="AlarmeCadastro"
      />
    </template>
    <template v-else>
      <form @submit.prevent class="form" role="form" ref="form">
        <FormParentsSelection
          v-if="showParentSelection"
          v-model="parent"
          v-bind:filters="filters"
          v-bind:locker="!cloneEnabled && data_id != 0"
        />
        <div v-if="ready">
          <FormCommonFieldsInline
            v-model="alarm"
            :disabled="!canNameIt"
            :errors="{name: !alarm.name ? 'invalid_name' : false}"
          />
          <div class="box box-solid">
          <div class="box-body">
          <div class="row">
            <div class="col-sm-7">
              <ProcessAreaSelection
                label="data"
                v-bind:parentProcessAreaId="parentProcessAreaId"
                v-bind:value="process_area"
                v-model="process_area"
              />
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label for="acknowledgment_enabled">
                  {{ $tc("configuration", 2) }}
                </label>
                <div class="checkbox-group">
                  <label class="checkbox-inline noselect">
                    <input
                      type="checkbox"
                      data-testid="acknowledgment-enabled"
                      v-model="alarm.acknowledgment_enabled"
                      id="acknowledgment_enabled"
                      v-bind:disabled="busy"
                    />
                    {{ $t("requires_acknowledgement") }}
                    <Tooltip :title="$t('hints.acknowledgement')" />
                  </label>
                  <label class="checkbox-inline noselect">
                    <input
                      type="checkbox"
                      data-testid="history-enabled"
                      v-model="alarm.history_enabled"
                      v-bind:disabled="busy"
                    />
                    {{ $t("history") }}
                    <Tooltip :title="$t('hints.history')" />
                  </label>
                </div>
              </div>
            </div>
            <div
              class="col-xs-12"
              v-if="history_enabled && !alarm.history_enabled"
            >
              <div
                style="margin: 0"
                class="alert alert-default text-center text-danger"
                v-html="$t('hints.history_disabled')"
              />
            </div>
          </div>

          <div class="data-configuration bg-whitesmoke">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="form-group form-group-sm">
                  <label
                    >{{ $t("trigger_criteria") }}
                    <Tooltip :title="$t('hints.trigger_criteria')"
                  /></label>
                  <div class="input-group">
                    <div class="input-group-addon">
                      {{ $t("value_of") }}
                    </div>
                    <input
                      type="text"
                      v-model="alarm.data.name"
                      disabled
                      class="form-control text-center disable-white"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-6">
                <div class="form-group form-group-sm">
                  <label for="comparison_operator">{{ $t("condition") }}</label>
                  <div class="input-group">
                    <select
                      class="form-control text-center"
                      id="comparison_operator"
                      v-model="alarm.alarm_trigger_condition.id"
                      v-bind:disabled="busy"
                      style="padding: 0"
                    >
                      <option
                        v-for="(item, ix) in conditions"
                        v-bind:key="ix"
                        v-bind:value="item.id"
                        v-bind:title="item.name"
                      >
                        {{ item.comparison_operator }}
                      </option>
                    </select>
                    <div
                      class="input-group-addon"
                      data-toggle="popover"
                      :data-content="(condition && condition.description) || ''"
                      v-bind:class="{
                        disabledClass: busy || isModelBased
                      }"
                    >
                      {{ (condition && condition.name) || "?" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-6">
                <div
                  class="form-group form-group-sm"
                  :class="{'has-error': !limitIsValid}"
                >
                  <label for="limit">
                    {{ $t("limit") }}
                    <Tooltip
                      v-if="isFloat"
                      :title="$t('hints.alarm_limit_float')"
                    />
                    <Tooltip
                      v-if="isString"
                      :title="$t('hints.alarm_limit_string')"
                    />
                    <Tooltip
                      v-if="!isFloat && !isString"
                      :title="$t('hints.alarm_limit_number')"
                    />
                  </label>
                  <DataValueInputDuration
                    v-if="isDuration"
                    v-model="alarm.limit"
                    :title="$t('duration')"
                    :disabled="busy"
                    :showDays="showDays"
                    :showSeconds="showSeconds"
                    :userUnit="unitLabel || 'seconds'"
                  />
                  <input
                    v-else-if="isString || isFloat"
                    type="text"
                    class="form-control "
                    data-testid="limit"
                    v-model="alarm.limit"
                    v-bind:disabled="busy"
                    id="limit"
                  />
                  <input
                    v-else
                    type="number"
                    class="form-control text-center"
                    data-testid="limit"
                    v-model="alarm.limit"
                    v-bind:disabled="busy"
                    step="any"
                    id="limit"
                  />
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <AlarmLevelForm
                  v-model="level"
                  :defaultOptions="defaultLevelOptions"
                  :disabled="busy"
                >
                  <template #label>
                    <label for="level">
                      {{ $tc("level", 1) }}
                      <Tooltip :title="$t('hints.level')" />
                    </label>
                  </template>
                </AlarmLevelForm>
              </div>
            </div>
          </div>

          <FormConfigNotification
            v-model="groups"
            style="margin-top: 2rem"
            :notification_mode="alarm.notification_mode"
            :notification_channels="notification_config"
            :enabled="alarm.notification_enabled"
            :canDisable="true"
            @enable="alarm.notification_enabled = true"
            @disable="alarm.notification_enabled = false"
            @notification_mode="alarm.notification_mode = $event"
          />

          <FormInlineModel
            v-if="isModel"
            v-model="connectorModel"
            v-bind:isConnector="false"
          />

          <div class="row" style="margin-top: 3rem" v-if="alarm_id && !isModel">
            <ClearHistory
              target="alarm"
              :targetId="alarm_id"
              class="col-md-4"
            />
          </div>
          </div>
          </div>
        </div>
        <div v-if="no_data">
          <div class="alert alert-info">
            <i class="icon fa fa-warning"></i> {{ $t("no_data") }}
          </div>
        </div>
        <FormFooterToolbar
          v-if="ready"
          v-bind:remove="canRemove"
          v-bind:busy="busy"
          v-bind:valid="isValid"
          v-bind:clone="true"
          v-bind:value="cloneEnabled"
          v-model="cloneEnabled"
          v-on:buttonCancelClick="onButtonCancelClick"
          v-on:buttonSaveClick="onButtonSaveClick"
          v-on:buttonCloneClick="onButtonCloneClick"
          v-on:buttonRemoveClick="onButtonRemoveClick"
          rule="AlarmeCadastro"
        />
      </form>
    </template>
    <EquipmentAlarmNotificationForm
      v-if="active_notification_form == 'email'"
      v-bind:alarms="[adaptedAlarm]"
      v-bind:save="false"
      v-bind:defaultOptions="notification_form_groups"
      v-model="email_notification"
      v-on:show="() => {}"
      v-on:hide="active_notification_form = ''"
    />

    <EquipmentAlarmNotificationForm
      v-if="active_notification_form == 'telegram'"
      v-bind:alarms="[adaptedAlarm]"
      v-bind:save="false"
      v-bind:defaultOptions="notification_form_groups"
      v-model="telegram_notification"
      v-on:show="() => {}"
      v-on:hide="active_notification_form = ''"
    />
  </section>
</template>

<script>
import FormBase from "@/components/registration/form-base.vue";
import FormParentsSelection from "@/components/registration/form-parents-selection.vue";
import FormCommonFieldsInline from "@/components/registration/form-common-fields-inline.vue";
import ProcessAreaSelection from "@/components/processarea-selection.vue";
import FormInlineModel from "@/components/registration/form-inline-model.vue";
import ClearHistory from "@/components/history/clear-history";
import Tooltip from "@/components/tooltip.vue";
import EquipmentAlarm from "@/services/equipment-alarm.js";
import EquipmentAlarmNotificationForm from "@/components/equipment-alarm-notification-form.vue";
import FormConfigNotification from "@/components/registration/form-config-notification.vue";
import ContractService from "@/services/contract.js";
import ConnectorService from "@/services/connector.js";
import DeviceService from "@/services/device.js";
import DataService from "@/services/data.js";
import EquipmentDataService from "@/services/equipment-data.js";
import messages from "@/i18n/alarm";
import DataValueInputDuration from "@/components/data-value-input-duration.vue";
import AlarmLevelForm from "@/components/registration/alarm-level-form.vue";

import {validNumber} from "@/plugins/utils.js";

const NOTIFICATION_MODE_ACTIVATE_AND_DEACTIVATE = 0;

function defaultData() {
  return {
    alarm: {
      id: "",
      name: "",
      process_area: {id: "0"},
      inherits_parent_process_area: true,
      data: null,
      enabled: true,
      alarm_trigger_condition: {id: ""},
      acknowledgment_enabled: false,
      limit: "",
      level: "",
      portal_data: null,
      user_data: null,
      etag: "",
      history_enabled: true,
      notification_mode: NOTIFICATION_MODE_ACTIVATE_AND_DEACTIVATE
    },
    process_area: {
      inherits_parent_process_area: true,
      id: 0
    },

    // the modal form will be displayed based on this model value
    notification_group_enabled: false, // temporary disabled till next demand.
    active_notification_form: "", // email | telegram | ""
    notification_config: null,
    // ===========================================
    // models that will be converted and persisted
    email_notification: {
      groups: [],
      timeout: 5
    },
    telegram_notification: {
      groups: [],
      timeout: 5
    },
    parent: {id: 0, name: ""},
    showParentSelection: true,
    connectorModel: {
      propagate: false
    },

    levelOptions: [],
    notification_only: false,
    history_enabled: false
  };
}
export default {
  name: "FormAlarm",
  extends: FormBase,
  i18n: {messages},
  components: {
    ProcessAreaSelection,
    // NotificationUserInputForm,
    EquipmentAlarmNotificationForm,
    FormConfigNotification,
    FormParentsSelection,
    Tooltip,
    ClearHistory,
    FormCommonFieldsInline,
    FormInlineModel,
    DataValueInputDuration,
    AlarmLevelForm
  },
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    device_id: {
      type: Number,
      required: true,
      default: 0
    },
    data_id: {
      type: Number,
      required: true,
      default: 0
    },
    alarm_id: {
      type: Number,
      required: true,
      default: 0
    },
    notificationOnly: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return defaultData();
  },
  watch: {
    "alarm.name": {
      handler() {
        this.$emit(
          "titleChanged",
          `${(this.alarmId &&
            (this.cloneEnabled ? this.originalName : this.alarm.name)) ||
            this.$tc("new", 1)}${
            this.cloneEnabled ? " (" + this.$t("copying") + ")" : ""
          }`
        );
      },
      deep: true
      // immediate: true
    },
    busy(n) {
      this.$emit("loading", n);
    },
    parent(n) {
      if (
        n &&
        n.id != ((this.alarm && this.alarm.data && this.alarm.data.id) || "")
      ) {
        this.busy = true;
        this.getDataById(n.id).then((data) => {
          if (data) {
            let alarm = JSON.parse(JSON.stringify(this.alarm));
            alarm.data = data;
            this.initData(alarm);
            this.nav(alarm.name);
          }
          this.ready = false;
          this.$nextTick(() => {
            this.ready = true;
            this.busy = false;
          });
        });
      }
    },
    alarm_id(n, o) {
      if (!n && o) {
        this.ready = false;
        this.showParentSelection = false;
        this.$nextTick(() => {
          this.resetData();
          this.setup();
          this.showParentSelection = true;
        });
      }
    },
    payload(n) {
      if (n && typeof this.$parent.updateHash == "function") {
        this.$parent.updateHash(n);
      }
    }
  },
  computed: {
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    deviceId() {
      return parseInt(this.device_id || this.$route.params.device_id || 0);
    },
    dataId() {
      return parseInt(this.data_id || this.$route.params.data_id || 0);
    },
    alarmId() {
      return parseInt(this.alarm_id || this.$route.params.alarm_id || 0);
    },
    connector() {
      return (
        (this.alarm &&
          this.alarm.data &&
          this.alarm.data.device &&
          this.alarm.data.device.connector) ||
        null
      );
    },
    device() {
      return this?.alarm?.data?.device || null;
    },
    filters() {
      let data = (this.alarm && this.alarm.data) || null;
      let device = (data && data.device) || null;
      let connector = (device && device.connector) || null;

      let device_disabled = this.device_id ? true : false;
      let data_disabled = this.data_id ? true : false;

      if (this.cloneEnabled) {
        device_disabled = false;
        data_disabled = false;
      }

      let ret = [
        {
          entry: {
            id: parseInt(connector && connector.id) || this.connectorId || 0
          },
          filter_by: "connector_id",
          label: "connector",
          service: this.newConnectorService(!self.alarm_id),
          disabled: this.connector_id ? true : false
        },
        {
          entry: {id: parseInt(device && device.id) || this.deviceId || 0},
          filter_by: "device_id",
          label: "device",
          service: new DeviceService(),
          disabled: device_disabled
        },
        {
          entry: {id: parseInt(data && data.id) || this.dataId || 0},
          filter_by: "data_id",
          label: "data",
          service: new DataService(),
          disabled: data_disabled,
          filter_function: (item) =>
            item && !item.is_local && parseInt(item.memory_size) == 1
        }
      ];
      return ret;
    },
    adaptedAlarm() {
      return this.service.alarmAdapter(this.alarm);
    },
    notification_form_groups() {
      let self = this;
      let channel = null;
      let source = null;
      if (!self.active_notification_form) return null;
      if (self.active_notification_form == "email") {
        channel = self.notification_channels.email; // email
        source = self.email_notification;
      } else if (self.active_notification_form == "telegram") {
        channel = self.notification_channels.telegram; // telegram
        source = self.telegram_notification;
      }
      let ret = {
        groups: [],
        timeout: source.timeout
      };
      for (var i in source.groups) {
        var group = JSON.parse(JSON.stringify(channel)); // create a new group
        group.name = source.groups[i].name;
        group.tags = source.groups[i].tags;
        ret.groups.push(group);
      }
      return ret;
    },
    isValid() {
      return this.alarm &&
        this.alarm.name &&
        this.process_area &&
        this.process_area.id &&
        this.limitIsValid
        ? true
        : false;
    },
    limitIsValid() {
      if (this.alarm.limit == "") return false;

      if (this.isFloat) {
        return validNumber(this.alarm.limit);
      }
      return true;
    },
    parentProcessAreaId() {
      return (
        (this.alarm &&
          this.alarm.data &&
          this.alarm.data.process_area &&
          this.alarm.data.process_area.id) ||
        0
      );
    },
    references() {
      return (
        ("config" in this.$root &&
          "references" in this.$root.config &&
          this.$root.config.references) ||
        {}
      );
    },
    conditions() {
      return (this?.references?.alarm_trigger_conditions || [])
        .filter(
          ({comparison_operator}) =>
            !this.isString ||
            comparison_operator == "==" ||
            comparison_operator == "!="
        )
        .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    },
    condition() {
      let self = this;
      let lst = self.conditions.filter(function(i) {
        return self.alarm.alarm_trigger_condition.id == i.id;
      });
      return (lst.length && lst[0]) || null;
    },
    payload() {
      let self = this;
      let payload = {...defaultData().alarm, ...self.alarm};
      if (self.alarm.id) {
        payload.id = self.alarm.id;
      }
      payload.data_id = this.parent.id;
      payload.process_area_id = this.process_area.id;
      payload.inherits_parent_process_area = this.process_area.inherits_parent_process_area;
      payload.alarm_trigger_condition_id = payload.alarm_trigger_condition.id;
      if (self.connectorModel.propagate) {
        payload.apply_changes_to_instances = true;
      }
      // portal data
      payload.portal_data = self.payload_notifications || {}; // parent with default notification
      if (this?.alarm?.portal_data?.tags) {
        payload.portal_data.tags = this?.alarm?.portal_data?.tags;
      }
      // payload.portal_data.level_options = this.levelOptions;
      delete payload.process_area;
      delete payload.data;
      delete payload.alarm_state;
      delete payload.alarm_trigger_condition;
      return payload;
    },
    removalMessage() {
      let msg = "";
      let item = this.alarm;
      if (item.data.device.connector.base_model) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("alarm");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      } else {
        msg = this.warningContent(
          "alarm",
          item.name,
          "you_wont_be_able_to_revert_this"
        );
      }
      return msg;
    },
    formatList() {
      return this.references.data_value_format_types || [];
    },
    dataFormat() {
      return (
        this.formatList.find(
          (i) => i.id == this?.alarm?.data?.value_format_type?.id || 0
        ) || null
      );
    },
    isDuration() {
      return this?.dataFormat?.format_mask === "duration";
    },
    showDays() {
      return (
        this.isDuration && /dd/i.test(this?.alarm?.data?.custom_format || "")
      );
    },
    showSeconds() {
      return (
        this.isDuration && /ss/i.test(this?.alarm?.data?.custom_format || "")
      );
    },
    unitLabel() {
      return this?.alarm?.data?.unity_label || "";
    },
    defaultLevelOptions() {
      // return this.$root.config.alarm.levels || [];
      return [];
    },
    level: {
      set(value) {
        if (value) {
          this.alarm.level = value.query || "";
          this.levelOptions = value.options;
        } else {
          this.alarm.level = "";
          this.levelOptions = [];
        }
      },
      get() {
        let entry = {
          query: this.alarm.level,
          options: this.levelOptions || []
        };
        return entry;
      }
    },
    canRemove() {
      return this.alarm_id && !this.isModelBased ? true : false;
    },
    canNameIt() {
      return this.cloneEnabled || !this.alarm.id || !this.isModelBased;
    },
    isString() {
      return this?.alarm?.data?.type == "string";
    },
    isFloat() {
      return this?.alarm?.data?.type == "float";
    }
  },
  methods: {
    getDataById(id) {
      let data = this.$store.getters["dashboard/dataList"].find(
        (i) => parseInt(i.id) === parseInt(id)
      );
      return data
        ? new Promise((resolve) => resolve(data))
        : this.fetchData(id);
    },
    resetData() {
      let data = defaultData();
      Object.keys(data).forEach((k) => {
        if (Object.prototype.hasOwnProperty.call(this.$data, k)) {
          this.$data[k] = data[k];
        }
      });
    },
    async fetchConnector(id) {
      return new Promise((resolve) => {
        let srv = new ConnectorService();
        srv.get(id).then((ret) => {
          resolve(ret);
        });
      });
    },
    async fetchDevice() {
      let self = this;
      return new Promise((resolve) => {
        let srv = new DeviceService();
        srv.get(self.device_id).then((ret) => {
          resolve(ret);
        });
      });
    },
    async fetchData(id) {
      return new Promise((resolve) => {
        this.parentService.get(id).then((ret) => {
          resolve(ret);
        });
      });
    },
    async fetchAlarm() {
      return this.service.get(this.alarm_id, this.contract && this.contract.id);
    },
    saveContract(payload) {
      let srv = new ContractService();
      srv.updateContract(payload).then((response) => {
        if (response && typeof response == "object" && response.id) {
          this.$store.dispatch("user/configureUserContract");
        } else {
          this.$swal({
            title: self.$t("item_could_not_be_saved"),
            text: self.$t("invalid_data"),
            icon: "error"
          });
        }
      });
    },
    updateAlarmLevelOptions() {
      // cOptions=contract alarm level options
      // lOptions=local alarm level options
      let lOptions = this?.levelOptions || [];
      if (
        !this?.contract?.id ||
        !this?.contract?.etag ||
        (!lOptions?.length &&
          !(this?.contract?.portal_data || {})?.alarm_level_options?.length)
      ) {
        return; // nothing to be updated
      }
      let payload = {
        portal_data: JSON.parse(
          JSON.stringify(this?.contract?.portal_data || {})
        ),
        etag: this?.contract?.etag,
        id: this?.contract?.id
      };
      payload.portal_data.alarm_level_options = JSON.parse(
        JSON.stringify(lOptions)
      );
      this.saveContract(payload);
    },
    _save(stay, addAnother) {
      let self = this;
      let payload = self.payload;
      self.busy = true;
      this.service.save(payload).then((ret) => {
        ret = ret && Array.isArray(ret) ? ret[0] : ret;
        self.busy = false;
        if (self.validateSaveResponse(ret)) {
          self.onSaveSuccess(ret, "alarm");
          self.updateAlarmLevelOptions();
          self.$store.dispatch("user/configureUserContract");
          self.alarm.id = ret.id;
          self.alarm.etag = ret.etag;
          self.exitSuccess = true;
          self.showAlert(() => {
            if (addAnother) {
              if (
                this.$route.path !=
                `/dashboard/edit/connector/${this.connector_id}/device/${this.device_id}/data/${this.data_id}/alarm/0`
              )
                this.$router.push(
                  `/dashboard/edit/connector/${this.connector_id}/device/${this.device_id}/data/${this.data_id}/alarm/0`
                );
              this.$emit("updateKey");
            } else if (stay) {
              if (self.$route.path.endsWith("/0")) {
                self.$router.push({
                  name: "route-alarm-form",
                  params: {
                    alarm_id: self.alarm.id,
                    data_id: self.alarm.data.id,
                    device_id: self.alarm.data.device.id,
                    connector_id: self.alarm.data.device.connector.id
                  }
                });
                self.nav(self.alarm.name);
              }
            } else {
              self.requestProperClose("save");
            }
          }, true);
        } else {
          self.showAlert();
        }
      });
    },
    save(stay, addAnother) {
      let self = this;
      let isNew = !self.alarm.id;
      let isModel = self.isModel;
      this.validateResourceQuota(
        "alarm_plural",
        this.contract.maximum_alarms,
        this.contract.registered_alarms - (!isNew || isModel ? 1 : 0)
      ).then((resp) => {
        if (resp == "proceed") {
          if (!isNew && !self.alarm.etag) {
            self.busy = true;
            self.service.get(self.alarm.id).then((alarm) => {
              if (alarm && alarm.etag) {
                self.alarm.etag = alarm.etag;
                self._save(stay, addAnother);
              } else {
                self.busy = false;
              }
            });
          } else {
            this._save(stay, addAnother);
          }
        } else if (resp == "upgrade") {
          this.$router.push("/dashboard/plan");
        }
      });
    },
    requestProperClose(action) {
      if (this.notificationOnly) {
        this.$emit("close", this.alarm);
      } else {
        this.close(action);
      }
    },
    clone() {
      let self = this;
      let payload = self.payload;
      delete payload.id;
      self.busy = true;
      this.service.duplicate(self.alarm_id, payload).then((ret) => {
        self.busy = false;
        if (self.validateSaveResponse(ret)) {
          if (typeof this.$parent.resetHash == "function") {
            this.$parent.resetHash();
          }
          self.$store.dispatch("user/configureUserContract");
          self.exitSuccess = true;
          self.showAlert(() => {
            self.close("clone");
          });
        } else {
          self.showAlert();
        }
      });
    },
    remove: function() {
      this.doRemove(this.payload, this.service, (removed) => {
        if (!removed) return;
        this.$store.dispatch("dashboard/removeResources", [
          {alarm_id: this.payload.id}
        ]);
      });
    },
    initData(alarm) {
      let self = this;
      if (alarm) {
        if (alarm.data && alarm.data.id) {
          let srv = new EquipmentDataService();
          alarm.data = srv.dataAdapter([alarm.data])[0];
          self.parent = {id: alarm.data.id, name: alarm.data.name};
          alarm.process_area = alarm.process_area || {id: 0};
          //
          // since there is no default option on settings.alarm_trigger_conditions
          // it makes use of a hardcoded constant 4 which means="greater or equal then" operator
          //
          alarm.alarm_trigger_condition = (alarm?.alarm_trigger_condition?.id &&
            alarm?.alarm_trigger_condition) || {
            id: alarm.data.type == "string" ? 1 : 4
          };

          // process area setup
          self.process_area.inherits_parent_process_area =
            alarm.inherits_parent_process_area;
          self.process_area.id =
            (alarm.process_area && alarm.process_area.id) || "";
          //=======================================
          // at last, remains default_notification only
          let notification_config = JSON.parse(
            JSON.stringify(this.$root.config.notification_channels)
          );
          for (var i in notification_config) {
            let channel = notification_config[i];
            Object.keys(channel.data_source).forEach((k) => {
              if (k === "default_notifications") {
                channel.data_source[k].short = "alarm";
                channel.data_source[k].icon = "fa fa-bell-o";
                channel.data_source[k].text = `${this.$tc(
                  "alarm_notifications",
                  1
                )} - ${this.$t("send_notifications_to")}`;
              } else {
                delete channel.data_source[k];
              }
            });
          }
          this.notification_config = notification_config;
          //=======================================
          self.initGroups(alarm, [
            {
              data_source: {
                default_notifications: {
                  attr: "emails",
                  title: self.$t("users_to_be_notified_by_email"),
                  text: "Email"
                }
              }
            },
            {
              data_source: {
                default_notifications: {
                  attr: "telegrams",
                  title: self.$t("users_to_be_notified_by_telegram"),
                  text: "Telegram"
                }
              }
            }
          ]);

          // alarm ack notifications by email
          self.email_notification = {
            groups: (
              (alarm.portal_data &&
                alarm.portal_data.alarms_unack_notifications &&
                alarm.portal_data.alarms_unack_notifications.groups) ||
              []
            ).map(function(i) {
              return {
                name: i.name,
                tags: i.emails
              };
            })
          };
          // alarm ack notifications by telegram
          self.telegram_notification = {
            groups: (
              (alarm.portal_data &&
                alarm.portal_data.alarms_unack_notifications &&
                alarm.portal_data.alarms_unack_notifications.groups) ||
              []
            ).map(function(i) {
              return {
                name: i.name,
                tags: i.telegrams
              };
            })
          };

          // self.levelOptions = alarm?.portal_data?.level_options || null;
          self.levelOptions = JSON.parse(
            JSON.stringify(
              this?.contract?.portal_data?.alarm_level_options || []
            )
          );

          // data setup
          self.$set(self, "alarm", JSON.parse(JSON.stringify(alarm)));
          if (alarm && "notification_enabled" in alarm) {
            this.$set(
              this.alarm,
              "notification_enabled",
              alarm.notification_enabled
            );
          }
          self.ready = true;
          return;
        }
      }
      this.nav("invalid_data");
    },
    setup() {
      return new Promise((resolve) => {
        let self = this;
        if (self.alarm_id) {
          //read the device and the connector at once
          self.busy = true;
          self.fetchAlarm().then((data) => {
            self.busy = false;
            if (!data) {
              self.showParentSelection = false;
              self.noData();
              return;
            }
            this.originalName = data.name;
            this.history_enabled = data.history_enabled;
            self.initData(data);
            if (self.$utils.gup("a") == "c") {
              this.nav(`${self.alarm.name} (${self.$t("copying")})`);
              self.cloneEnabled = true;
              self.alarm.name = "";
            } else if (this.$utils.gup("a") == "d") {
              this.nav(`${self.alarm.name} (${self.$t("deleting")})`);
              self.$nextTick(function() {
                self.onButtonRemoveClick();
              });
            } else {
              this.nav(this.alarm.name);
            }
            resolve();
          });
          return;
        }
        if (self.data_id) {
          //read the device, creates an dummy data and associate them
          self.busy = true;
          self.fetchData(self.data_id).then((data) => {
            self.busy = false;
            if (data) {
              let alarm = defaultData().alarm;
              alarm.data = data;
              self.initData(alarm);
              self.nav(self.$tc("new"));
            }
            resolve();
          });
          return;
        }
        if (self.device_id) {
          self.busy = true;
          self.fetchDevice(self.device_id).then((device) => {
            self.busy = false;
            if (device) {
              let alarm = defaultData().alarm;
              alarm.data = {
                id: "0",
                name: "",
                device: {
                  id: this.device_id || 0,
                  name: device.name,
                  connector: {
                    id: this.connector_id || 0,
                    name: device?.connector?.name || ""
                  }
                }
              };
              self.initData(alarm);
              self.nav(self.$tc("new"));
            }
            resolve();
          });
          return;
        }
        if (self.connector_id) {
          self.busy = true;
          self.fetchConnector(self.connector_id).then((connector) => {
            self.busy = false;
            if (connector) {
              let alarm = defaultData().alarm;
              alarm.data = {
                id: "0",
                name: "",
                device: {
                  id: 0,
                  name: "",
                  connector: {
                    id: connector.id || 0,
                    name: connector?.name || ""
                  }
                }
              };
              self.initData(alarm);
              self.nav(self.$tc("new"));
            }
            resolve();
          });
          return;
        }
        let alarm = defaultData().alarm;
        alarm.data = {
          id: "0",
          name: "",
          device: {
            id: this.device_id || 0,
            name: "",
            connector: {
              id: this.connector_id || 0,
              name: ""
            }
          }
        };
        self.initData(alarm);
        self.nav(self.$tc("new"));
        resolve();
      });
    },
    nav(title) {
      this.$emit("titleChanged", title);
      if (this.alarm?.data?.device?.connector) {
        let items = [
          {name: "connector_plural", url: `/dashboard/edit/connector`},
          {
            name: this.alarm.data.device.connector.name,
            url: `/dashboard/edit/connector/${this.alarm.data.device.connector.id}`
          }
        ];
        items = items.concat([
          {
            name: "device_plural",
            url: `/dashboard/edit/connector/${this.alarm.data.device.connector.id}/device`
          },
          {
            name: this.alarm.data.device.name,
            url: `/dashboard/edit/connector/${this.alarm.data.device.connector.id}/device/${this.alarm.data.device.id}`
          }
        ]);
        items = items.concat([
          {
            name: "data_plural",
            url: `/dashboard/edit/connector/${this.alarm.data.device.connector.id}/device/${this.alarm.data.device.id}/data`
          },
          {
            name: this.alarm.data.name,
            url: `/dashboard/edit/connector/${this.alarm.data.device.connector.id}/device/${this.alarm.data.device.id}/data/${this.alarm.data.id}`
          },
          {
            name: "alarm_plural",
            url: `/dashboard/edit/connector/${this.alarm.data.device.connector.id}/device/${this.alarm.data.device.id}/data/${this.alarm.data.id}/alarm`
          },
          {name: this.alarm.name, url: ""}
        ]);
        this.$emit("navChanged", {
          previous: "/dashboard/edit/connector",
          items: items
        });
      }
    },
    onButtonCancelClick() {
      this.requestProperClose("cancel");
    },
    equipmentAlarmRequest(forceUpdate) {
      var query = {
        resource: "alarm",
        connectorId: this.connectorId,
        forceUpdate: forceUpdate
      };
      return this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    }
  },
  beforeCreate() {
    this.parentService = new DataService();
    this.service = new EquipmentAlarm();
  },
  beforeDestroy() {
    this.parentService = null;
    this.service = null;
  },
  created() {
    this.cloneEnabled = this.$utils.gup("a") == "c";
    this.notification_only =
      this?.$route?.query?.notification == "true" || this.notificationOnly;
    this.$emit("titleChanged", this.alarm_id ? this.alarm_id : this.$tc("new"));
    this.rule = "AlarmeCadastro";

    this.equipmentAlarmRequest(true).then((r) => {
      this.setup().then(() =>
        $(this.$el)
          .find("[data-toggle=popover]")
          .popover({
            delay: {show: 500},
            placement: "auto",
            trigger: "hover"
          })
      );
    });
  }
};
</script>

<style scoped>
label.checkbox-inline {
  font-size: 12pt;
  margin: 10px 20px 0 20px;
  vertical-align: baseline;
}

label.checkbox-inline > input {
  width: 18px;
  height: 18px;
}

.data-configuration {
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
}

.bg-whitesmoke {
  background-color: whitesmoke;
}

.skin-dark .bg-whitesmoke {
  background-color: #171b1d;
}

input.disable-white {
  background-color: white;
}
.btn-notification-config {
  margin: 0 0 0 20px;
}

.duration-field::v-deep .form-group-sm .form-control {
  height: 34px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.disabledClass {
  background-color: #eee;
}
</style>
